#myGrid{
    height: 100% !important;
}

.ag-popup .ag-menu{
    position: relative !important;
    z-index: 111 !important;
}
.Globaltable-Header-Wrapper .ant-picker{
    height: 28px !important;
    border: 1px solid #018ee0 !important;
}
.Globaltable-Header-Wrapper .task_filter_box{
    margin-top: 1px !important;
    height: 27px !important;
}
.extra-component-request-toRight .css-b62m3t-container{
    height: 28px !important;
    width: 160px !important;
    margin: 0px !important
}
.extra-component-request-toRight .css-13cymwt-control{
    border: 1px solid #018ee0 !important;;
}
.extra-component-request-toRight .css-13cymwt-control .css-1dimb5e-singleValue{
    height: 20px !important;
    color: #018ee0 !important;
    font-weight: bold !important;
    font-family: 'Avenir Next LT W02' !important;
    font-size: 0.8rem !important;
}
.extra-component-request-toRight .dayview-select .css-13cymwt-control .css-1dimb5e-singleValue{
    height: 24px !important;
    color: #018ee0 !important;
    font-weight: bold !important;
    font-family: 'Avenir Next LT W02' !important;
    font-size: 0.8rem !important;
}
.extra-component-request-toRight .css-t3ipsp-control .css-1dimb5e-singleValue{
    height: 20px !important;
    color: #018ee0 !important;
    font-weight: bold !important;
    font-family: 'Avenir Next LT W02' !important;
    font-size: 0.8rem !important;
}
.extra-component-request-toRight .dayview-select .css-t3ipsp-control .css-1dimb5e-singleValue{
    height: 24px !important;
    color: #018ee0 !important;
    font-weight: bold !important;
    font-family: 'Avenir Next LT W02' !important;
    font-size: 0.8rem !important;
}
.extra-component-request-toRight .css-13cymwt-control{
    height: 28px !important;
    min-height: 25px !important;
}
.extra-component-request-toRight .css-t3ipsp-control{
    height: 28px !important;
    min-height: 25px !important;
}
.extra-component-request-toRight .css-13cymwt-control .css-1xc3v61-indicatorContainer svg{
    position: absolute !important;
    top: 3px !important;
    right: 2px !important;
    color: #018ee0 !important;
}
.extra-component-request-toRight .css-t3ipsp-control .css-15lsz6c-indicatorContainer svg{
    position: absolute !important;
    top: 3px !important;
    right: 2px !important;
    color: #018ee0 !important;
}
.extra-component-request-toRight{
    display: flex;
    gap: 10px;
}
.extra-component-request-toRight .job-action-box .btn{
    background: rgb(0, 123, 255) !important;
    color: white !important;
    height: 1.8rem !important;
    line-height: 16px !important;
    font-size: 14px !important;
    margin-right: 0px !important;
}
.ag-theme-alpine .ag-ltr .ag-cell{
    border-right: solid   #F3F6FA!important;
    /* border: 1px solid #babfc7!important; */
    border-bottom: solid #F3F6FA !important;
}