.Associate-Email-Modal .main-div {
  margin-top: -9px;
}

.Associate-Email-Modal .tabs-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
  position: relative;
  margin-bottom: 1rem;
  overflow-y: auto;
}

.Associate-Email-Modal .data-container {
  display: flex;
  gap: 2px;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  max-height: 12rem;
  height: auto;
  overflow: auto;
}

.Associate-Email-Modal .search:focus-visible {
  outline: none;
}
.Associate-Email-Modal .search {
  width: 96%;
  position: relative;
  margin-bottom: 2px;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
}

.Associate-Email-Modal .tab {
  padding: 2px 4px;
  font-size: large;
  background-color: #f2f2ff;
  color: #6f6f6f;
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
}

.Associate-Email-Modal .selectedItemsWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: flex-start;
}

.Associate-Email-Modal .selectedItoms {
  border: 1px solid gray;
  color: brown;
  padding: 1px;
  border-radius: 8px;
}

.Associate-Email-Modal .tab.active {
  padding: 2px 4px;
  font-size: large;
  background-color: #018ee0;
  color: #ffffff !important;
  border-radius: 12px 12px 0px 0px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
}

.Associate-Email-Modal .tab2 {
  padding: 2px 14px;
  cursor: pointer;
  margin: 3px;
  border-radius: 50px;
}

/* .tab2.active {
border: 2px solid rgb(161, 161, 161) !important;
color: #000000 !important;
background: #f2f2f2 !important;
} */
.Associate-Email-Modal .Associate-Row {
  padding: 0px 19px !important;
}

.Associate-Email-Modal .Associate-Col {
  /* border: 1px solid #eeeeee; */
  border-radius: 9px;
  padding: 7px;
}

.Associate-Email-Modal .ant-modal-body {
  padding-top: 6px !important;
  padding-bottom: 0px !important;
}

.Associate-Email-Modal .tabs-container {
  /* border-bottom: 1px solid #eeeeee; */
  margin-bottom: 0px !important;
  width: 100%;
  display: flex;
}
.Associate-Email-Modal .selected-tiles {
  background: rgb(255, 255, 232);
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}
.Associate-Email-Modal .selected-tiles:hover .Delete-Icon{
  display: flex;
    align-items: center;
    justify-content: center;
    color: #fa3737;
    font-size: 12px;
    cursor: pointer;
    background-color: #ffffff;
    padding: 1px 3px;
    position: absolute;
    right: -8px;
    border: 1.5px solid #585858;
    border-radius: 10px;
    z-index: 1;
    top: -3px;
}
.Associate-Email-Modal .Delete-Icon{
  display: none;
}
.AutoTaggingCheckBox{
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0px;
  top: 4px;
  width: 16%;
}
.AutoTaggingCheckBox input{
  position: relative;
  top: 3px;
}
.Associate-Email-Modal .ant-modal-footer{
  margin-top: 0px !important;
}
.Associate-Email-Modal .tab{
  font-size: 1rem !important;
  border: 1px solid #e1e1e1;
  min-width: max-content;
}

.tabs-container .individual-tabs {
    display: flex;
    gap: 10px;
    width: 83%;
    overflow: scroll;
}
.main-div .tabs-container ::-webkit-scrollbar{
  width: 6px !important;
  height: 6px !important;
}

.main-div .tabs-container ::-webkit-scrollbar:hover{
  width: 6px !important;
  height: 6px !important;
}
.collapsible-container {
  width: 100%;
  padding: 20px;
}

.collapsible-section {
  margin-bottom: 5px;
}

.collapsible-label {
  display: flex;
  align-items: center;
  padding: 2px;
  /* background-color: #fdfdfd; */
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  position: relative;
  text-indent: 5px;
  border-radius: 4px;
  width: 100%;
}

.collapsible-label span {
  flex: 1;
}

.toggle-icon {
  margin-left: 10px;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.toggle-icon.expanded {
  transform: rotate(180deg); /* Rotate the icon when expanded */
}

.task-list {
  margin-left: 10px;
  margin-top: 5px;
}
.subtasks{
  /* margin-left: 5px; */
  margin-top: 5px;
}
.collapsible-container .pipeline-list {
  display: block;
  padding: 5px 0px 5px 11px;  
  font-size: 13px;
}
.subtask {
  margin-left: 10px; 
  padding: 2px;
  background-color: #e9e9e9;
  border-radius: 4px;
  margin-bottom: 5px;
}
.nametile{
  border: 1px solid lightgray;
  padding: 0px 10px;
  border-radius: 10px;
  font-size: small;
}