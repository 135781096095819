.View-Pipeline-Wrapper .Task-Header{
    display: flex;
    flex-direction: row;
    height: 10% !important;
    width: 100%;
    /* background-color: aqua; */
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border-bottom: 1px solid #e6e6e6;
}
.View-Pipeline-Modal-Wrapper .ant-select{
    height: 34px !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-selector{
    box-shadow: none !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-selector:active{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-selector:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.Add-task-Wrapper .ant-select-selector:focus-visible{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-arrow{
    display: none !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-selection-search input{
    height: 32px !important;
}
.View-Pipeline-Modal-Wrapper .ant-select-selection-item{
    font-size: 12px !important;
    font-weight: 500 !important;
}
.dropdown-create-task .ant-select-item-option-content{
    font-size: 13px !important;
    font-weight: 500 !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal{
    top: 75px !important;
    right: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-content{
    width: 85vw !important;
    height: auto !important;
    max-height: 85vh !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-content .ant-modal-close {
    top: 4px !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-content .ant-modal-close svg{
    fill: black !important;
    width: 13px !important;
    height: 13px !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-header{
    /* border-bottom: 1.2px solid rgb(13, 13, 13) !important; */
    border-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    margin: 0px !important;
    padding: 5px !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-footer{
    margin: 0px !important;
    padding: 5px !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-body{
    padding: 1rem 2rem !important;
}
.View-Pipeline-Modal-Wrapper .task-details-wrapper{
    height: 70vh;
    overflow: scroll;
    border-right: 0.1px solid rgb(206, 206, 206);
    font-size: 12px;
    font-weight: 500;
}
.View-Pipeline-Modal-Wrapper .task-details-wrapper .form-group{
margin-bottom: 0.2rem !important;
}
.View-Pipeline-Modal-Wrapper .add-task-info .row{
    margin: 1px -15px !important;
}
.dependecy-modal{
    z-index: 999999 !important;
}
.create-task-field-label{
    display: flex;
    align-items: center;
}
.milestone-name-input:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.due-date-range .rdrDefinedRangesWrapper{
    width: 150px !important;
}
.rdrStaticRangeLabel{
    width: 9rem !important;
}
/* .create-task-datepicker .ant-popover-content{
    width: 29rem !important;
}
.create-task-datepicker .ant-popover-inner{
    position: relative !important;
    top: 16rem !important;
} */
.create-task-time_est_fields .time_unit{
    position: absolute;
    right: 6px;
    top: 8px;
    font-size: 12px;
}
/* New Task Modal CSS */
.View-Pipeline-Modal-Wrapper .ant-modal-body{
    padding: 0px !important;
    margin: 0px !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-content{
    overflow: hidden !important;
}
.View-Pipeline-Modal-Wrapper .ant-modal-close,.View-Pipeline-Modal-Wrapper .ant-modal-footer{
    display: none !important;
}
.View-Pipeline-Wrapper{
    display: flex;
    height: 80vh;
    flex-direction: column;
}
.View-Pipeline-Wrapper .Task-Header{
    display: flex;
    flex-direction: row;
    height: 10% !important;
    width: 100%;
    /* background-color: aqua; */
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border-bottom: 1px solid #e6e6e6;
}
.View-Pipeline-Wrapper .View-Pipeline-Modal-Body{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.View-Pipeline-Wrapper .View-Pipeline-Modal-Body-Wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
}
.View-Pipeline-Wrapper .col, .View-Pipeline-Wrapper .row{
    margin: 0px !important;
    padding: 0px !important;
}
.View-Pipeline-Wrapper .View-Pipeline-Modal-Body-Child1{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.View-Pipeline-Wrapper .Task-Modal-Body-Child2{
    display: flex;
    flex-direction: column;
    width: 5rem;
    font-size: 12px;
    height: 100%;
}
.View-Pipeline-Wrapper .View-Pipeline-Info-Wrapper{
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 34px 8px 34px !important;
    height: 100%;
    border-right: 1px solid #e6e6e6;
}
.View-Pipeline-Wrapper .Task-Activity-Wrapper{
   /* overflow-y: scroll; */
   overflow: hidden;
   padding: 0px !important;
   border-right: 1px solid #e6e6e6;
}
.View-Pipeline-Info-Wrapper .Pipeline-Name{
    height: 3rem;
    font-size: 1.5rem;
}
.View-Pipeline-Info-Wrapper .Custom-Field-Pipeline-Row{
    display: flex;
    align-items: center;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    border-bottom: 0.1px solid #cfcece !important;
    padding-bottom: 5px !important;
}
.View-Pipeline-Info-Wrapper .Custom-Field-Row{
    display: flex;
    align-items: center;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    border-bottom: 0.1px solid #cfcece !important;
    padding-bottom: 0px !important;
}
.View-Pipeline-Info-Wrapper .SupervisorBtn{
    border: none !important;
    justify-content: flex-start !important;
    box-shadow: none !important;
}
.View-Pipeline-Info-Wrapper .ant-tabs-tab-btn{
    font-size: 1rem !important;
    font-weight: 600;
}
.Task-Modal-Body-Child2 .Task-Sidebar{
    display: flex;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    padding-top: 15px;
}
.Task-Modal-Body-Child2 .Task-Sidebar .SidebarBtns:hover{
   color: #4096ff;
   cursor: pointer;
}
.View-Pipeline-Info-Wrapper .SupervisorBtn:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
    color: black !important;
}
.View-Pipeline-Wrapper .Task-Activity-Wrapper .Activity-Header{
    display: flex;
    width: 100%;
    height: 10%;
    padding: 5px 10px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
}
.View-Pipeline-Wrapper .TimePicker-Estimate{
    border: none;
}
.View-Pipeline-Wrapper .TimePicker-Estimate:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.View-Pipeline-Wrapper .Task-Activity-Wrapper .Activity-Header .header{
    font-size: 1rem;
    font-weight: 600;
}
.View-Pipeline-Wrapper .Task-Activity-Wrapper .Activity-Body{
    display: flex;
    height: 90%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #f4f4f4;
}
.View-Pipeline-Wrapper .PipelineNameWrapper{
    position: sticky;
    top: 0;
    background: white;
    z-index: 100;
    border-bottom: 1px solid #e6e6e6;
    /* padding: 0px 0px 10px 0px !important; */
}
/* .View-Pipeline-Wrapper .Pipeline-Primary-Data-wrapper{
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 0px 10px 0px !important;
} */
.Pipeline-Primary-Data{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px !important;
}
.Pipeline-Primary-Data .primary-data{
    padding: 0px !important;
    width: max-content !important;
}
.Pipeline-Primary-Data .pipeline-divider{
    width: 5px !important;
    margin: 10px !important;
    border-inline-start: 1px solid rgba(5, 5, 5, 0.3) !important;
}
.pipeline-info-popover{
    top: 230px !important;
}
.pipeline-info-popover .ant-popover-inner-content{
    width: 285px !important;
    background: #fafafa !important;
    padding: 15px !important;
}
.pipeline-info-popover .ant-popover-inner-content .Custom-Field-Col-1{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.Tasklist-Table .ant-table-tbody td{
    padding: 10px !important;
}
.Tasklist-Tab-Dropdown .ant-select-selector{
    background: rgba(0, 0, 0, 0.06) !important;
}
.Tasklist-Tab-Dropdown .ant-select-arrow{
    display: block !important;
}
.View-Pipeline-Modal-Body-Wrapper .View-Pipeline-Info-Wrapper .ant-tabs-nav{
    position: sticky !important;
    top: 49px !important;
    z-index: 111 !important;
    background-color: #fff !important;
}
.TasksList-Table{
    overflow: scroll;
}
.TasksList-Table .TaskGroup2Card{
    padding: 0px !important;
    margin: 0px !important;
}
.add-row-btn {
    position: absolute;
    right: 0px;
    top: -3.5rem;
    z-index: 1111;
}
.View-Pipeline-Modal-Root-Wrapper .ant-modal-wrap{
    z-index: 9998 !important;
}

.Add-task-Root-Wrapper .ant-modal-wrap{
    z-index: 9999 !important;
}
.Add-task-Root-Wrapper .ant-modal-mask{
    z-index: 9999 !important;
}
.TasksList-Table .bottom-bar{
    padding: 10px !important;
}
.View-Pipeline-Modal-Wrapper .ServicesDropdown{
   min-width:12rem !important;
   left: 25rem !important;
   border: 1px solid #e6e6e6 !important;
}