iframe#webpack-dev-server-client-overlay{display:none!important}

:root {
  --background-color: #ffffff;
  --text-color: #5b6c97;
  --background-color-gray:#f8f8f8;
}
.App {
  text-align: center;
}
.Global-Text-Color{
  color: var(--text-color) !important;
}
.Global-Text-Color .ant-select-selector{
  color: var(--text-color) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

 /* new css */

body{
  height: 100vh !important;
  overflow: hidden !important;
}
#root iframe{
  height: 100vh !important;
}

#root #iam_iframe{
 height: 100vh !important;
}

#iam_iframe{
 height: 100vh !important;
}

.create-actions .dropdown-menu.show{
  width: 225px !important;
}

 /* new css */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .user-dropdown .dropdown-menu.show{
  width: 250px !important;
} */

.ant-popover-inner-content{
  padding: 0px !important;
  border-radius: 0px !important;
}

.ant-popover-inner-content .dropdown-menu-right{
  top: -11px !important;
  right: 10px;
  display: block;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.ant-popover {
  z-index: 9999 !important;
}
.ant-select-dropdown {
  z-index: 999999 !important;
}

.common-time-tracker{
  position: relative;
  width: 20rem;
}
.common-time-tracker .cancel-icon {
  position: absolute;
  top: -4px;
  right: 3px;
}

.ant-select-dropdown{
  z-index: 99999;
}

.time-track-duration-common-app{
  padding-left: 10px !important;
}

.time-track-duration-common-app .user-time-track{
  padding: 0px !important;
  border-top: 0px !important;
}

.time-track-duration-common-app .user-time-track .col tr{
  display: flex;
}

.time-track-duration-common-app .user-time-track .col tr td{
  /* width: 25% !important; */
  text-align: center !important;
}


.time_track_scroll table tbody .collapse{
  margin: 6px 0px !important;
}

.notification-search-icon svg{
 
    right: 20px !important;
    top: 9px !important;
    font-size: 12px !important;

}
a{
  color: #40a9ff;
}




.highlight-color {
  animation: 7s bgcolorchange ;
}


@keyframes bgcolorchange {
  0% {
    background: white;
  }
  50% {
    background: rgb(152, 220, 245);
}

100% {
  background: rgb(181, 220, 235);
}
}

.ant-modal-wrap{
  z-index: 9999 !important
}
.ant-modal-mask{
  z-index: 9998 !important
}

.popup-notification{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.popup-notification .ant-modal-content{
  width: 345px !important;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.popup-notification .ant-modal-body{
  padding-top: 20px !important;
}  
.popup-notification  .ant-modal-title{
  margin-top: .25rem!important;  
}

.popup-notification .ant-modal-header{
  border-bottom: 0px !important;
  border-radius: 10px;
}

.popup-notification .ant-modal-footer{
  border-top: 0px !important;
  text-align: left !important;
}

.popup-notification .btn-outline-primary{
  padding: 4px 25px !important;
  border-radius: 30px !important;
  margin-right: 10px;
}

.popup-notification .btn-outline-secondary{
  padding: 4px 25px !important;
  border-radius: 30px !important;
}

.ag-watermark{
  display: none !important;
}

.Chat-Notification-Overlay{
  width: 29rem !important;
}
.Chat-Notification-Overlay ul{
  padding: 10px !important;
}
.Chat-Notification-Overlay ul .notificationTime{
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  justify-content: flex-end !important;
  gap: 5px !important;
}
.Chat-Notification-Overlay ul .notifications-hyperlinks a:nth-child(2){
  color: #40a9ff !important;
}

.ant-popover-inner-content .accountexpansion{
  top: 0px !important;
  display: block !important;
  right: -1.6rem !important;
}

.ant-modal-content{
 padding: 0px !important;
}

.ant-modal-header{
  padding: 16px 24px !important;
  background: #fff !important;
  /* border-radius: 12px !important; */
  border: none !important;
  border-radius: 19px 22px 0px 0px !important;
  border-bottom: 1px solid #eeeeee !important;
}
.ant-modal-body{
  padding: 20px !important;
}
.ant-modal-footer{
  border: 1px solid #f0f0f0 !important;
  padding: 10px !important;
  border: none !important;
  border-radius: 0px 0px 0px 0px !important;
  border-top: 1px solid #eeeeee !important;
}
.ant-dropdown-menu-title-content a{
  text-decoration: none !important;
}
.Reminder-Icon-Header{
  display: flex;
  width: 1.3rem;
  color: white;
  margin: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  top: -1px;
}
.Reminder-Icon-Header .notification-count{
  position: relative !important;
    top: 3px !important;
}
.alarm-icon {
  /* Define your icon styles */
}

.red-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.Reminder-Icon-Header.has-alarm::after {
  content: '';
  position: absolute;
  top: -5px;
  right: -2px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.My-Ant-Modal .ant-modal-close:hover{
  background-color: #ff3535d1 !important;
}
.My-Ant-Modal .ant-modal-close{
  position: absolute !important;
    top: -14px !important;
    right: -9px !important;
    font-weight: 600 !important;
    background: #ff353582 !important;
    color: white !important;
    line-height: 100% !important;
    display: flex !important; 
    align-items: center !important;
    justify-content: center !important;
}
.My-Ant-Modal .ant-modal-close-x{
  font-size: 14px !important;
  font-weight: 600 !important;
}
.My-Ant-Modal .ant-modal-header{
  padding: 7px 11px !important;
  border-radius: 8px 8px 0px 0px !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #007bff !important;
  display: flex !important;
}
.My-Ant-Modal .ant-modal-body {
  padding: 0px 10px !important;
  overflow: scroll !important;
  height: 87% !important;
}
.My-Ant-Modal .ant-modal-header .ant-modal-title{
  color: white !important;
}
.My-Ant-Modal{
  display: flex !important;
    top: 141px !important;
    border-radius: 50px !important;
    justify-content: center !important;
    width: 40% !important;
    height: 30% !important;
    right: auto !important;
    max-height: 73% !important;
}
.Snooze-Popover .Snooze-Content .Preset-Cards{
  display: flex;
  background-color: antiquewhite;
  align-items: center;
  justify-content: center;
  padding: 3px 0px;
  border-radius: 18px;
}
.Snooze-Popover .Snooze-Content .Custome-Snooze-Time{
  display: flex;
  gap: 12px;
  margin-top: 10px !important;
}
.Snooze-Popover .Snooze-Content .row{
  margin: 0px !important;
  gap: 6px;
}
.notificationFilters .edit-colum-button{
  border-radius: 3px !important;
  align-items: center;
  /* border-color: #018ee0 !important; */
  /* color: #018ee0 !important; */
  display: flex !important;
  justify-content: center;
}
.notificationArea .about-deal-search input{
  padding: 4px 10px !important;
  border: 2px solid #b0bed0 !important;
  position: relative !important;
  top: 0px !important;
}
.notificationArea .about-deal-search{
  padding: 0px 5px 0px 14px !important;
}
.notificationArea .search-filter{
  width: 100% !important;
  padding: 3px 0px 3px 6px !important;
}
.compact-picker input{
  margin-left: 20px !important;
  margin-right: 0px !important;
}
.pac-container{
  z-index: 9999 !important;
}
.Module-Selection-Wrapper .Module-Wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Module-Selection-Wrapper .Module-Selection-Header{
  background-color: #018ee0;
    color: white;
    font-size: 14px;
    padding: 3px 7px;
    border-radius: 3px;
}
.Module-Selection-Wrapper .Module-Wrapper{
  padding: 2px 5px;
}
.Notification-Setting-Popover .ant-dropdown-menu-item:last-child{
  padding: 0px !important;
}
.Notification-Setting-Popover .ant-dropdown-menu-submenu-expand-icon{
  display: none !important;
}
.Notification-Setting-Popover .ant-dropdown-menu-item-icon{
  position: absolute !important;
    top: 0.68rem !important;
    left: 0rem !important;
}
.global-action-btn-for-tables button {
  background: transparent;
  border: 0;
  color: #018ee0;
  font-weight: 500;
  text-transform: capitalize;
  padding: 1px 6px;
}
.global-action-btn-for-tables button:hover {
  text-decoration: underline;
}
.Elipsis-Class{
  cursor:default;
  display: block !important; /* Ensures the span behaves like a block element for width settings */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis */
  white-space: nowrap; /* Prevent text wrapping */
  /* border: 1px solid #ccc; */
}
.custom-sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky !important;
  right: 0;
  background-color: #f4f4f4; 
  z-index: 1; 
}

.ant-tooltip{
  z-index: 9999 !important;
}
.p0m0{
  padding: 0px !important;
  margin: 0px !important;
}
.custome-currency-input{
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  width: 100%;
  height: 33px;
  border-radius: 6px;
}
.custome-currency-input:focus-visible{
  outline: none;
}
.Justify-Centre-Class{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}
.User-Outline-Class{
  width: 1.5rem !important;
  height: 1.5rem !important;
  display: flex;
  align-items: center;
  background: #f3f3f3;
  border-radius: 17px !important;
  border: 1px dotted #000000 !important;
  opacity: 0.6;
  justify-content: center;
}
.notificationText .notifications-hyperlinks a{
  margin: 5px 2px !important;
  display: inline-block !important;
}
.Custom-Input-Wrapper{
  color: #5b6c97;
}
.Custom-Input-Wrapper .labeltext {
  position: absolute;
  z-index: 1;
  height: auto;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 64%, rgba(0,212,255,0) 69%);
  top: -0.7rem;
  padding: 0px 4px;
  font-weight: 600;
  color: #5b6c97;
}