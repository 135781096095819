.Roles-Wrapper{
    display: flex;
    width: 100%;
    height: calc(100% - 1rem);
    position: relative;
    flex-direction: column;
    top: -1rem !important;
    z-index: 1;
}
.Button-Group-Class{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin: 0px 0px;
}
.Role-Modal{
    width: 37rem !important;
}
.Role-Modal .ant-modal-content{
    width: 100% !important;
}
.Roles-Wrapper .Blueprint-Body{
    display: flex;
    width: 100%;
    height: calc(100%);
    background-color: white;
}

.CustomeRoleUserTag{
    /* padding: 3px !important; */
    display: flex !important;
    justify-content: flex-start !important;
    background: transparent;
    border: none;
    align-items: center;
    gap: 3px !important;
}
.CustomeRoleUserTag .Icon{
    width: 1.5rem;
    height: auto;
    border-radius: 50%;
    border: 1px solid #d7d7d7;
}
.ant-select-selection-item .Icon svg , .ant-select-dropdown .ant-tag .Icon svg{
    width: 1.2rem !important;
    height: 1.2rem !important;
    opacity: 0.5;
}
 /* White placeholder for bottom bar condition */
  .white-placeholder .ant-select-selection-placeholder {
    color: white;
  }
  .New-User-Picker-Popover .Content{
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 3px;
  }
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .New-User-Picker-Popover .Content .Option{
    
  }