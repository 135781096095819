.Status-Wrapper{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 14px;
    margin: 5px 0px !important;
}
.Main-Status-Button{
    position: relative;
}
.Main-Status-Button2{
    position: relative;
}

.Main-Status-Button::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 100%;
    background-color: #5b6c97;
    margin-left: 10px;
    position: absolute;
    top: 0;
    right: -7px;
  }
.Status-Wrapper .ant-btn{
    height: 24px !important;
    font-size: small !important;
    font-weight: 600 !important;
}
.dropdown-status-label span:nth-child(2){
    display: block;
    overflow: hidden;
    max-width: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.Criteria-Tooltip .ant-tooltip-content{
    width: max-content !important;
}
.Criteria-Tooltip .ant-tooltip-inner{
    background-color: rgb(193 193 193 / 85%) !important;
}
.Criteria-Tooltip .ant-tooltip-arrow:before {
    background: rgb(193 193 193 / 85%) !important;
}
.requiredMissingFields .ant-modal-footer{
    display: block !important;
}
.requiredMissingFields .ant-modal-content{ 
    right: 5rem !important;
    box-shadow: 1px 1px 15px 0px gray !important;
}
.requiredMissingFields .ant-modal-body{
    margin-top: -10px !important;
}
.headingMsg{
    font-size: medium;
    font-weight: 500;
    color: #5a6c96;
}
.fieldsRendererClassMainWrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: .5em;
}
.requiredMissingFields .ant-modal-title {
    color: #504e4e !important;
}
.bulletPoints{ 
        list-style-type: disc; 
        padding-left: 20px;      
}
.customList{
    margin-bottom: 3px;
    font-weight: 500;
    color: #626b68;
    padding-left: 2em;
}